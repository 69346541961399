import React, { useEffect, useState } from "react";
import { Progress, Button, Modal, message, Avatar, Collapse } from "antd";
import cookies from "js-cookie";

const ViewAdminTask = () => {
  const [tasks, setTasks] = useState([]);
  const getTasks = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/admin/getAllAdminTasks",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.get("token")}`,
          },
        }
      );
      const json = await response.json();
      if (response.status === 200) {
        setTasks(json.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTasks();
  }, []);
  const [openedData, setOpenedData] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const createOrUpdateTasks = async (data) => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/admin/createOrUpdateAdminTask",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.get("token")}`,
          },
          body: JSON.stringify(data),
        }
      );
      const json = await response.json();
      if (response.status === 200) {
        const taskIndex = tasks.findIndex((task) => task.id === data.id);
        if (taskIndex > -1) {
          const updatingTasksData = [...tasks];
          updatingTasksData[taskIndex].completed = data.completed;
          updatingTasksData[taskIndex].task = data.task;
          updatingTasksData[taskIndex].remarks = data.remarks;
          console.log(updatingTasksData);
          setTasks(updatingTasksData);
          setIsCreating(false);
          setModalIsOpen(false);
          setOpenedData(null);
        }
        message.success("Task updated!");
      } else if (response.status === 201) {
        const dataToPush = { ...json.data };
        setTasks([...tasks, dataToPush]);
        setIsCreating(false);
        setModalIsOpen(false);
        setOpenedData(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      style={{
        height: "calc(100% - 80px)",
        width: "calc(100% - 40px)",
        marginLeft: "20px",
        background: "red",
        background:
          "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
        padding: "20px",
        borderRadius: "10px",
        marginTop: "5px",
        gap: "5px",
        color: "black",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <Modal
        open={modalIsOpen}
        onCancel={() => {
          setIsCreating(false);
          setModalIsOpen(false);
          setOpenedData(null);
        }}
        width={"66%"}
        footer={null}
      >
        <div>
          <div
            style={{
              color: "#142C44CC",
              fontWeight: 800,
              fontSize: "18px",
              fontFamily: "Manrope, sans-serif",
            }}
          >
            {!isCreating ? "Update Task" : "Create Task"}
          </div>
          {openedData !== null && !isCreating ? (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                createOrUpdateTasks(openedData);
              }}
            >
              <div style={{ marginTop: "10px" }}>Tasks title</div>
              <textarea
                value={openedData.task || ""}
                onChange={(e) => {
                  setOpenedData({ ...openedData, task: e.target.value });
                }}
                style={{
                  width: "calc(100% - 16px)",
                  height: "70px",
                  resize: "none",
                  borderRadius: "5px",
                  border: "1px solid lightgrey",
                  outline: "none",
                  padding: "8px",
                  fontSize: "10px",
                }}
              />
              <div style={{ marginTop: "10px" }}>Tasks Remarks</div>
              <textarea
                value={openedData.remarks || ""}
                onChange={(e) => {
                  setOpenedData({ ...openedData, remarks: e.target.value });
                }}
                style={{
                  width: "calc(100% - 16px)",
                  height: "70px",
                  resize: "none",
                  borderRadius: "5px",
                  border: "1px solid lightgrey",
                  outline: "none",
                  padding: "8px",
                  fontSize: "10px",
                }}
              />
              <button
                style={{
                  background: "rgb(17, 92, 166)",
                  color: "white",
                  padding: "7px 30px",
                  border: "none",
                  marginTop: "10px",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                type="submit"
              >
                Update
              </button>
            </form>
          ) : openedData ? (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                createOrUpdateTasks(openedData);
              }}
            >
              <div style={{ marginTop: "10px" }}>Tasks title</div>
              <textarea
                value={openedData.task || ""}
                onChange={(e) => {
                  setOpenedData({ ...openedData, task: e.target.value });
                }}
                style={{
                  width: "calc(100% - 16px)",
                  height: "70px",
                  resize: "none",
                  borderRadius: "5px",
                  border: "1px solid lightgrey",
                  outline: "none",
                  padding: "8px",
                  fontSize: "10px",
                }}
              />
              <div style={{ marginTop: "10px" }}>Tasks Remarks</div>
              <textarea
                value={openedData.remarks || ""}
                onChange={(e) => {
                  setOpenedData({ ...openedData, remarks: e.target.value });
                }}
                style={{
                  width: "calc(100% - 16px)",
                  height: "70px",
                  resize: "none",
                  borderRadius: "5px",
                  border: "1px solid lightgrey",
                  outline: "none",
                  padding: "8px",
                  fontSize: "10px",
                }}
              />
              <button
                style={{
                  background: "rgb(17, 92, 166)",
                  color: "white",
                  padding: "7px 30px",
                  border: "none",
                  marginTop: "10px",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                type="submit"
              >
                Create
              </button>
            </form>
          ) : null}
        </div>
      </Modal>
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: "#142C44CC",
              fontSize: "20px",
              fontFamily: "Manrope,sans-serif",
              fontWeight: 800,
              marginBottom: "15px",
            }}
            onClick={() => {
              setIsCreating(true);
              setModalIsOpen(true);
              setOpenedData({ task: "", remarks: "", completed: 0 });
            }}
          >
            Admin Tasks
          </div>
        </div>
      </div>
      <div style={{ display: "flex", gap: "12px" }}>
        <div
          style={{
            height: "80vh",
            background: "rgba(256,256,256,0.4)",
            width: "47%",
            borderRadius: "8px",
            padding: "5px",
          }}
        >
          <div
            style={{
              color: "#142C44CC",
              fontSize: "15px",
              fontWeight: "800",
              fontFamily: "Manrope, sans-serif",
              marginBottom: "8px",
            }}
          >
            Ongoing
          </div>
          <div
            style={{
              width: "100%",
              height: "100%",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {tasks.map((tas, index) =>
              !tas.completed ? (
                <div
                  key={index}
                  style={{
                    padding: "7px",
                    background: "rgba(256,256,256,1)",
                    marginBottom: "7px",
                    borderRadius: "8px",
                    cursor: "pointer",
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setModalIsOpen(true);
                    setOpenedData(tas);
                  }}
                >
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    style={{ marginLeft: "7px" }}
                  >
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        console.log(e.target.checked);
                        const dataToUpdate = { ...tas };
                        dataToUpdate.completed = e.target.checked;
                        createOrUpdateTasks(dataToUpdate);
                      }}
                      checked={tas.completed}
                    />
                  </div>
                  <div>
                    <div
                      style={{
                        color: "#142C44CC",
                        fontSize: "10px",
                        fontWeight: "700",
                      }}
                    >
                      {tas.task}
                    </div>
                    <div style={{ color: "rgb(80,80,80)", fontSize: "10px" }}>
                      {tas.remarks}
                    </div>
                  </div>
                </div>
              ) : null
            )}
          </div>
        </div>
        <div
          style={{
            height: "80vh",
            background: "rgba(256,256,256,0.4)",
            width: "47%",
            borderRadius: "8px",
            padding: "5px",
          }}
        >
          <div
            style={{
              color: "#142C44CC",
              fontSize: "15px",
              fontWeight: "800",
              fontFamily: "Manrope, sans-serif",
              marginBottom: "8px",
            }}
          >
            Completed
          </div>
          <div
            style={{
              width: "100%",
              height: "100%",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {tasks.map((tas, index) =>
              tas.completed ? (
                <div
                  key={index}
                  style={{
                    padding: "7px",
                    background: "rgba(256,256,256,1)",
                    marginBottom: "7px",
                    borderRadius: "8px",
                    cursor: "pointer",
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setModalIsOpen(true);
                    setOpenedData(tas);
                  }}
                >
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    style={{ marginLeft: "7px" }}
                  >
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        console.log(e.target.checked);
                        const dataToUpdate = { ...tas };
                        dataToUpdate.completed = e.target.checked;
                        createOrUpdateTasks(dataToUpdate);
                      }}
                      checked={tas.completed}
                    />
                  </div>
                  <div>
                    <div
                      style={{
                        color: "#142C44CC",
                        fontSize: "10px",
                        fontWeight: "700",
                      }}
                    >
                      {tas.task}
                    </div>
                    <div style={{ color: "rgb(80,80,80)", fontSize: "10px" }}>
                      {tas.remarks}
                    </div>
                  </div>
                </div>
              ) : null
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAdminTask;
