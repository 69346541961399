import { Space, Table, Tag, Radio, Button, Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { format } from "date-fns";

const TableAdvertisement = ({
  data,
  fetchData,
  onRowClick,
  onSelectionChange,
}) => {
  const [selectionType, setSelectionType] = useState("checkbox");
  const [modalStates, setModalStates] = useState({});
  const [screenWidth, setScreenWidth] = useState("1920");

  const formatDate = (dateString) => {
    if (!dateString) return "-"; // Return a default value if the date string is not provided

    const date = new Date(dateString); // Create a Date object from the date string
    const formattedDate = format(date, "dd MMMM yyyy"); // Use date-fns to format the date in the desired format

    return formattedDate;
  };

  useEffect(() => {
    onSelectionChange(data); // Call the function passed as a prop
  }, [data, onSelectionChange]);

  const handleDelete = async (ads_id) => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/ads/deleteAdById/${ads_id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );

      if (response.status === 200) {
        message.success("Ad deleted successfully");

        // Close the modal
        setModalStates((prevStates) => ({
          ...prevStates,
          [ads_id]: false,
        }));

        // Fetch the data again
        fetchData();
      } else {
        message.error("Ad unable to deleted");
      }
    } catch (error) {
      console.log("Failed to delete user", error);
    }
  };

  useEffect(() => {
    const initialStates = {};
    data.forEach((item) => {
      initialStates[item.ads_id] = false;
    });
    setModalStates(initialStates);
  }, [data]);

  useEffect(() => {
    const initialStates = {};

    data.forEach((item) => {
      initialStates[item.ads_id] = false;
    });
    setModalStates(initialStates);
  }, [data]);

  const showModal = (ads_id) => {
    setModalStates((prevStates) => ({
      ...prevStates,
      [ads_id]: true,
    }));
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function addMonthsToDate(dateString, monthsToAdd) {
    const date = new Date(dateString); // Parse the input date
    const newDate = new Date(date);

    // Add months
    newDate.setMonth(newDate.getMonth() + monthsToAdd);

    return newDate.toISOString(); // Convert back to ISO string if needed
  }

  const columns = [
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "15px",
            fontWeight: "600",
          }}
        >
          Name
        </span>
      ),
      dataIndex: "title",
      key: "title",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          {text ? `${text.charAt(0).toUpperCase() + text.slice(1)}` : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "15px",
            fontWeight: "600",
          }}
        >
          Publish Period
        </span>
      ),
      dataIndex: "publish_period",
      key: "publish_period",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          {text ? `${text.charAt(0).toUpperCase() + text.slice(1)} Month` : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "15px",
            fontWeight: "600",
          }}
        >
          Description
        </span>
      ),
      dataIndex: "description",
      key: "description",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          {text ? `${text.charAt(0).toUpperCase() + text.slice(1)}` : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "15px",
            fontWeight: "600",
          }}
        >
          Screen
        </span>
      ),
      dataIndex: "screen",
      key: "screen",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          {text ? `${text.charAt(0).toUpperCase() + text.slice(1)}` : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "15px",
            fontWeight: "600",
          }}
        >
          Expires
        </span>
      ),
      dataIndex: "expires",
      key: "expires",
      render: (text, record) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          {formatDate(
            new Date(
              addMonthsToDate(
                record.created_at,
                parseFloat(record.publish_period)
              )
            )
          )}
        </span>
      ),
    },
    {
      key: "action",

      // fixed: 'right',
      render: (text, record) => (
        <Button type="primary" onClick={() => showModal(record.ads_id)} danger>
          Delete
        </Button>
      ),
    },
  ];

  return (
    <div>
      <Table
        rowKey="ads_id"
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 5 }}
        scroll={{
          x: 1000,
        }}
        bordered
        onRow={(record) => ({
          onClick: (event) => {
            if (event.target.type !== "checkbox") {
              onRowClick(record);
            }
          },
        })}
        style={{
          borderRadius: "10px 10px 10px 10px",
          cursor: "pointer",
          margin: screenWidth > 1200 ? "0px 20px" : "0px",
          width: screenWidth > 1200 ? "auto" : "30%",
        }}
      />
      {data &&
        data.length > 0 &&
        data.map((item) => (
          <Modal
            style={{ backgroundColor: "rgba(0, 0, 0, 0)", margin: "10% auto" }}
            open={modalStates[item.ads_id]}
            onCancel={() =>
              setModalStates((prevStates) => ({
                ...prevStates,
                [item.ads_id]: false,
              }))
            }
            footer={[
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 16,
                }}
              >
                <Button
                  type="primary"
                  onClick={() => handleDelete(item.ads_id)}
                >
                  Yes
                </Button>
                <Button
                  type="default"
                  onClick={() =>
                    setModalStates((prevStates) => ({
                      ...prevStates,
                      [item.ads_id]: false,
                    }))
                  }
                >
                  No
                </Button>
              </div>,
            ]}
          >
            Are you sure you want to delete this ad?
          </Modal>
        ))}
    </div>
  );
};

export default TableAdvertisement;
