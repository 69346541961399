import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import { mockTransactions } from "../../data/mockData";
import TableDelayedRequest from "../../components/DelayedPaymentsTable";
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import warn_ico from "../../assets/img/exclamation.png";
import { Modal, Input, Button, message } from "antd";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import { saveAs } from "file-saver";

const DelayedPayments = () => {
  const [data, setData] = useState([]);
  const history = useHistory();
  const [screenWidth, setScreenWidth] = useState(1920);
  const [isBeeFinancial, setIsBeeFinancial] = useState(false);
  const [noCheckBoxError, setNoCheckBoxError] = useState(false);
  const [openedLoans, setOpenedLoans] = useState(null);
  const [checkboxChecked, setCheckboxChecked] = useState([]);
  function generateDate() {
    const today = new Date(); // Get the current date
    const day = String(today.getDate()).padStart(2, "0"); // Get the day and ensure 2 digits
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Get the month and ensure 2 digits
    const year = today.getFullYear(); // Get the year

    return `${day}${month}${year}`; // Concatenate into ddmmyyyy
  }

  
  function generateDate2() {
    const today = new Date(); // Get the current date
    const day = String(today.getDate()).padStart(2, "0"); // Get the day and ensure 2 digits
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Get the month and ensure 2 digits
    const year = today.getFullYear(); // Get the year

    return `${year}${month}${day}`; // Concatenate into ddmmyyyy
  }


  function generateRandomId() {
    return Math.floor(100 + Math.random() * 900); // Generate a random number between 100 and 999
  }
  const headerTemplate = [
    { text: "20", length: 2, align: "left", description: "Collection code" },
    {
      text: `${generateRandomId()}`,
      length: 3,
      align: "left",
      description: "Batch number random id",
    },
    {
      text: generateDate2(),
      length: 8,
      align: "left",
      description: "Date of generating document YYYYMMDD",
    },
    {
      text: "OCBCSGSGXXX",
      length: 11,
      align: "left",
      description: "Originating bank code",
    },
    {
      text: "602180564001",
      length: 34,
      align: "left",
      description: "Account number",
    },
    { text: "", length: 3, align: "left", description: "Blank filler" },
    {
      text: "On Behalf of SmartL",
      length: 20,
      align: "left",
      description: "On behalf of",
    },
    { text: "", length: 120, align: "left", description: "Blank filler" },
    { text: "", length: 4, align: "left", description: "Blank filler" },
    {
      text: "FAST",
      length: 4,
      align: "left",
      description: "Clearing using GIRO of FAST",
    },
    { text: "", length: 16, align: "left", description: "Blank filler" },
    {
      text: generateDate(),
      length: 8,
      align: "left",
      description: "Date Transaction should happen DDMMYYYY",
    },
    {
      text: "",
      length: 4,
      align: "left",
      description: "Leave blank for immediate",
    },
    { text: "", length: 1, align: "left", description: "Batch booking filler" },
    { text: "", length: 762, align: "left", description: "Blank filler" },
  ];

  const generateFixedWidthTxt = () => {
    let secondLineOnwards = [];
    const dataAvailableForGen = data.filter(
      (dat) =>
        dat.direct_debit !== false &&
        dat.repayments.filter((repa) => repa.status === "pending").length > 0 &&
        dat.direct_debit.status
    );
    checkboxChecked.map((key, index) => {
      const dataToPush = [
        {
          text: dataAvailableForGen?.[key]?.direct_debit?.swift_bic,
          length: 11,
          align: "left",
          description: "Bank Code",
        },
        {
          text: dataAvailableForGen?.[key]?.direct_debit?.acc_number,
          length: 34,
          align: "left",
          description: "Employee account number",
        },
        {
          text: dataAvailableForGen?.[key]?.direct_debit?.name,
          length: 35,
          align: "left",
          description: "Employee name",
        },
        {
          text: "",
          length: 105,
          align: "left",
          description:
            "Blank filler for name as FAST only allow up till 35 char",
        },
        { text: "SGD", length: 3, align: "left", description: "Currency" },
        {
          text: String(
            dataAvailableForGen?.[key]?.repayments?.[0]?.repay_amount * 100
          )
            .replace(".", "")
            .padStart(17, "0"),
          length: 17,
          align: "left",
          description: "Currency in cents",
        },
        {
          text: `Loan ${dataAvailableForGen?.[key]?.loan_id} repayment`,
          length: 35,
          align: "left",
          description: "Payment Details",
        },
        {
          text: "COLL",
          length: 4,
          align: "left",
          description: "Collection code",
        },
        {
          text: dataAvailableForGen?.[key]?.direct_debit?.direct_debit_id,
          length: 35,
          align: "left",
          description: "Debtor Reference",
        },
        { text: "", length: 140, align: "left", description: "Blank filler" },
        { text: "", length: 140, align: "left", description: "Blank filler" },
        {
          text: "",
          length: 1,
          align: "left",
          description: "Blank filler (Send remitance via)",
        },
        {
          text: "",
          length: 255,
          align: "left",
          description: "Blank filler (Send details only if invoice involved)",
        },
        {
          text: "",
          length: 12,
          align: "left",
          description: "Blank filler (Proxy type only for paynow transactions)",
        },
        {
          text: "",
          length: 140,
          align: "left",
          description:
            "Blank filler (Proxy value only for paynow transactions)",
        },
        { text: "", length: 33, align: "left", description: "Blank filler" },
      ];
      console.log(dataToPush);
      secondLineOnwards.push(dataToPush);
    });

    console.log([[...headerTemplate], ...secondLineOnwards]);

    const totalData = [[...headerTemplate], ...secondLineOnwards];

    const lines = totalData.map(
      (line) =>
        line
          .map((col) => {
            const { text, length, align } = col;

            // Truncate if text exceeds length
            const truncated =
              text.length > length ? text.slice(0, length) : text;

            // Pad based on alignment
            if (align === "left") {
              return truncated.padEnd(length, " ");
            } else if (align === "right") {
              return truncated.padStart(length, " ");
            } else {
              throw new Error("Invalid alignment value");
            }
          })
          .join("") // Combine all columns into a single line
    );

    // Combine all lines with newline characters
    const result = lines.join("\n");
    const formatDateForFilename = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
    };
    // Create and save the .txt file
    const blob = new Blob([result], { type: "text/plain;charset=utf-8" });
    saveAs(blob, `giro_${formatDateForFilename(new Date())}.txt`);
    message.success("Giro document created.");
  };

  const getUserDetails = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/profile/getUserDetails",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      if (response.status === 200) {
        const json = await response.json();
        if (json.User.lender_dd) {
          console.log("allow egiro");
          setIsBeeFinancial(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const handleCheckboxChange = (index) => {
    setCheckboxChecked((prev) => {
      if (prev.includes(index)) {
        // Remove index if already checked
        return prev.filter((i) => i !== index);
      } else {
        // Add index if not already checked
        return [...prev, index];
      }
    });
  };

  const [repaymentSelected, setRepaymentSelected] = useState(null);
  const [isMobile, setIsMobile] = useState(
    Cookies.get("ismobile") === "yes" ? true : false
  );

  useEffect(() => {
    if (screenWidth < 1200) {
      Cookies.set("ismobile", "yes");
      setIsMobile(true);
    } else {
      Cookies.set("ismobile", "no");
      setIsMobile(false);
    }
  }, [screenWidth]);

  const viewLenderPayments = async (record) => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/viewLenderPayments`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      const json = await response.json();
      setData(json.Payments);
    } catch (error) {
      console.error("Error retrieving loan detail:", error);
    }
  };

  useEffect(() => {
    viewLenderPayments();
  }, []);

  useEffect(() => {
    // To redirect user if not authenticated
    const isAuthenticated = Cookies.get("token");
    const user_account_type = Cookies.get("user_account_type");

    if (!isAuthenticated) {
      history.push("/auth/signin");
    } else if (user_account_type === "borrower") {
      history.push("/b-dashboard");
    }
  }, [history]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return "-"; // Return a default value if the date string is not provided

    const date = new Date(dateString); // Create a Date object from the date string
    const formattedDate = format(date, "MMM dd, yyyy"); // Use date-fns to format the date in the desired format

    return formattedDate;
  };

  const handleMakePayment = async (repay_id, lender_id) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + Cookies.get("token"));

    const options = {
      method: "GET",
      headers: headers,
    };

    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/makePayment?repay_id=${repay_id}&lender_id=${lender_id}`,
        options
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      } else {
        const json = await response.json();
        message.success("Payment received updated.");
        // const paymentIndex = data.findIndex((loan) =>
        //   loan.repayments.some((repayment) => repayment.repay_id === repay_id)
        // );

        // if (paymentIndex > -1) {
        //   const repaymentIndex = data[paymentIndex].repayments.findIndex(
        //     (repayment) => repayment.repay_id === repay_id
        //   );

        //   if(repaymentIndex > -1){
        //     const updatingData = [...data]
        //     updatingData[paymentIndex].repayments[repaymentIndex].
        //   }
        // }

        viewLenderPayments();
        console.log(json);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      style={{
        height: "calc(100% - 80px)",
        width: "calc(100% - 40px)",
        marginLeft: "20px",
        background: "red",
        background:
          "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
        padding: "20px",
        borderRadius: "10px",
        marginTop: "5px",
        gap: "5px",
        color: "black",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <Modal
        width="60%"
        footer={null}
        open={openedLoans !== null}
        zIndex={800}
        onCancel={() => {
          setOpenedLoans(null);
          setRepaymentSelected(null);
        }}
      >
        {data.length > 0 && openedLoans !== null ? (
          <div
            onClick={() => setRepaymentSelected(null)}
            style={{
              color: "#142C44CC",
              fontFamily: "Manrope,sans-serif",
              fontWeight: 500,
              fontSize: 12,
            }}
          >
            <div
              style={{
                color: "#142C44CC",
                fontSize: "20px",
                fontFamily: "Manrope,sans-serif",
                fontWeight: 800,
              }}
            >
              Loan repayments
            </div>
            <div
              style={{
                background: "rgba(17, 92, 166, 0.1)",
                width: "100%",
                borderRadius: "8px",
                overflow: "hidden",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                marginTop: "15px",
              }}
            >
              {data?.[openedLoans]?.repayments?.map((payments, index) => (
                <div
                  key={index}
                  className={
                    payments.status === "pending" &&
                    new Date(payments.repay_due_date) < new Date()
                      ? "blink"
                      : ""
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    setRepaymentSelected(index);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "5px 20px",
                    background:
                      payments.status === "pending" &&
                      new Date(payments.repay_due_date) < new Date()
                        ? "red"
                        : index % 2
                        ? "rgba(17, 92, 166, 0.1)"
                        : "white",
                    color:
                      payments.status === "pending" &&
                      new Date(payments.repay_due_date) < new Date()
                        ? "white"
                        : "#142C44CC",
                    fontFamily: "Manrope,sans-serif",
                    fontWeight: 500,
                    fontSize: 12,
                    cursor: "pointer",
                  }}
                >
                  <div style={{ width: "5%" }}>{index + 1}</div>
                  <div style={{ width: "25%" }}>
                    {formatDate(new Date(payments.repay_due_date))}
                  </div>
                  <div style={{ width: "25%" }}>
                    {payments.repay_amount?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "SGD",
                    })}
                  </div>
                  <div style={{ width: "25%" }}>
                    {payments.status === "done"
                      ? "Repayment Paid"
                      : payments.status === "pending"
                      ? new Date(payments.repay_due_date) < new Date()
                        ? `Late by ${payments.late_day} days`
                        : "Pending"
                      : "Pending"}
                  </div>
                </div>
              ))}
            </div>
            {repaymentSelected !== null ? (
              <div>
                <div
                  style={{
                    color: "#142C44CC",
                    fontSize: "20px",
                    fontFamily: "Manrope,sans-serif",
                    fontWeight: 800,
                    marginTop: "30px",
                  }}
                >
                  Repayment Status
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "15px",
                    alignItems: "center",
                    marginTop: "8px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: 800,
                    }}
                  >
                    Repayment ID:
                  </div>
                  <div>
                    {
                      data?.[openedLoans]?.repayments?.[repaymentSelected]
                        ?.repay_id
                    }
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "15px",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontWeight: 800,
                    }}
                  >
                    Status:
                  </div>
                  <div>
                    {data?.[openedLoans]?.repayments?.[repaymentSelected]
                      ?.status === "done"
                      ? "Repayment Paid"
                      : data?.[openedLoans]?.repayments?.[repaymentSelected]
                          ?.status === "pending"
                      ? new Date(
                          data?.[openedLoans]?.repayments?.[
                            repaymentSelected
                          ]?.repay_due_date
                        ) < new Date()
                        ? `Late by ${data?.[openedLoans]?.repayments?.[repaymentSelected]?.late_day} days`
                        : "Pending"
                      : "Pending"}
                  </div>
                </div>
                <div
                  style={{ display: "flex", gap: "15px", alignItems: "center" }}
                >
                  <div
                    style={{
                      fontWeight: 800,
                    }}
                  >
                    Due Date:
                  </div>
                  <div>
                    {formatDate(
                      new Date(
                        data?.[openedLoans]?.repayments?.[
                          repaymentSelected
                        ]?.repay_due_date
                      )
                    )}
                  </div>
                </div>
                <div
                  style={{ display: "flex", gap: "15px", alignItems: "center" }}
                >
                  <div
                    style={{
                      fontWeight: 800,
                    }}
                  >
                    Repayment amount:
                  </div>
                  <div>
                    {data?.[openedLoans]?.repayments?.[
                      repaymentSelected
                    ]?.repay_amount?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "SGD",
                    })}
                  </div>
                </div>
                {data?.[openedLoans]?.repayments?.[repaymentSelected]
                  ?.status === "pending" ? (
                  <div style={{ display: "flex" }}>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        handleMakePayment(
                          data?.[openedLoans]?.repayments?.[repaymentSelected]
                            ?.repay_id,
                          data?.[openedLoans]?.repayments?.[repaymentSelected]
                            ?.lender_id
                        );
                      }}
                      style={{
                        background: "rgb(17, 92, 166)",
                        color: "white",
                        padding: "7px 30px",
                        border: "none",
                        marginTop: "10px",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}
                    >
                      Payment received
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}

            {data?.[openedLoans]?.repayments?.filter(
              (repay) =>
                repay.status === "pending" &&
                new Date(repay.repay_due_date) < new Date()
            ).length > 0 ? (
              <div>
                <div
                  style={{
                    color: "#142C44CC",
                    fontSize: "20px",
                    fontFamily: "Manrope,sans-serif",
                    fontWeight: 800,
                    marginTop: "30px",
                  }}
                >
                  Late Payment status
                </div>
                <div>
                  There is currently a repayment due from
                  {data?.[openedLoans]?.borrower_details?.entity_name} on the{" "}
                  {formatDate(
                    new Date(
                      data?.[openedLoans]?.repayments?.filter(
                        (repay) =>
                          repay.status === "pending" &&
                          new Date(repay.repay_due_date) < new Date()
                      )?.[0]?.repay_due_date
                    )
                  )}{" "}
                  if the payment is received, you can mark it as paid by
                  clicking on the button below.
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMakePayment(
                        data?.[openedLoans]?.repayments?.filter(
                          (repay) =>
                            repay.status === "pending" &&
                            new Date(repay.repay_due_date) < new Date()
                        )?.[0]?.repay_id,
                        data?.[openedLoans]?.repayments?.filter(
                          (repay) =>
                            repay.status === "pending" &&
                            new Date(repay.repay_due_date) < new Date()
                        )?.[0]?.lender_id
                      );
                    }}
                    style={{
                      background: "rgb(17, 92, 166)",
                      color: "white",
                      padding: "7px 30px",
                      border: "none",
                      marginTop: "10px",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                  >
                    Payment received
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </Modal>
      <div style={{ width: "100%", marginBottom: "15px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: "#142C44CC",
              fontSize: "20px",
              fontFamily: "Manrope,sans-serif",
              fontWeight: 800,
            }}
          >
            On-going loans
          </div>
        </div>
        <div
          style={{
            background: "rgba(256,256,256,0.4)",
            borderRadius: "8px",
            width: "100%",
            height: isBeeFinancial ? "35vh" : "80vh",
            color: "rgb(80,80,80)",
            overflow: "hidden",
            overflowY: "auto",
            marginTop: "15px",
            boxShadow: "0px 0px 10px #142C441A",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "5px 8px",
              background: "rgba(17, 92, 166, 0.1)",
              color: "#142C44CC",
              fontSize: "20px",
              fontFamily: "Manrope,sans-serif",
              fontWeight: 800,
              fontSize: 14,
              gap: "1%",
            }}
          >
            <div style={{ width: "35%" }}>Entity Name</div>
            <div style={{ width: "15%" }}>Loan start date</div>
            <div style={{ width: "15%" }}>Loan balance</div>
            <div style={{ width: "15%" }}>Interest Earned</div>
            <div style={{ width: "15%" }}>Monthly-installment</div>
            <div style={{ width: "15%" }}>Current due-date</div>
            <div style={{ width: "15%" }}>Payment late days</div>
          </div>
          {data.map((pay, index) => (
            <div
              key={index}
              onClick={() => {
                setOpenedLoans(index);
              }}
              className="row_click"
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "12px 8px",
                background: index % 2 ? "rgba(17, 92, 166, 0.1)" : "white",
                color: "#142C44CC",
                fontFamily: "Manrope,sans-serif",
                fontWeight: 500,
                fontSize: 12,
                cursor: "pointer",
                gap: "1%",
              }}
            >
              <div
                style={{
                  width: "35%",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {pay?.repayments?.filter(
                  (repay) => repay.status === "pending"
                )?.[0]?.late_day > 0 ? (
                  <div
                    className="blink"
                    style={{
                      background: "red",
                      height: "7px",
                      width: "7px",
                      borderRadius: "50%",
                    }}
                  ></div>
                ) : null}
                {pay?.borrower_details?.entity_name}
              </div>
              <div style={{ width: "15%" }}>
                {formatDate(new Date(pay?.repayments?.[0]?.created_at))}
              </div>
              <div style={{ width: "15%" }}>
                {(
                  pay.principal -
                  pay.repayments?.filter((repay) => repay.status === "done")
                    .length *
                    pay.repayments[0].repay_amount
                )?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "SGD",
                })}
              </div>
              <div style={{ width: "15%" }}>
                {pay.repayments
                  ?.filter((repay) => repay.status === "done")
                  ?.reduce((sum, repay) => sum + (repay.repay_interest || 0), 0)
                  ?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "SGD",
                  })}
              </div>
              <div style={{ width: "15%" }}>
                {pay?.repayments
                  ?.filter((repay) => repay.status === "pending")?.[0]
                  ?.repay_amount?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "SGD",
                  })}
              </div>
              <div style={{ width: "15%" }}>
                {formatDate(
                  pay?.repayments?.filter(
                    (repay) => repay.status === "pending"
                  )?.[0]?.repay_due_date
                )}
              </div>
              <div style={{ width: "15%" }}>
                {pay?.repayments?.filter(
                  (repay) => repay.status === "pending"
                )?.[0]?.late_day === null
                  ? "Not Applicable"
                  : pay?.repayments?.filter(
                      (repay) => repay.status === "pending"
                    )?.[0]?.late_day + " Days"}
              </div>
            </div>
          ))}
        </div>
        {isBeeFinancial ? (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <div
                style={{
                  color: "#142C44CC",
                  fontSize: "20px",
                  fontFamily: "Manrope,sans-serif",
                  fontWeight: 800,
                }}
              >
                Pending E-Giro Transactions
              </div>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  if (checkboxChecked.length < 1) {
                    message.error("No transaction selected.");
                  } else {
                    generateFixedWidthTxt();
                  }
                }}
                style={{
                  background: "rgb(17, 92, 166)",
                  color: "white",
                  padding: "7px 30px",
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                Export GIRO
              </div>
            </div>
            <div
              style={{
                background: "rgba(256,256,256,0.4)",
                borderRadius: "8px",
                width: "100%",
                height: "35vh",
                color: "rgb(80,80,80)",
                overflow: "hidden",
                overflowY: "auto",
                marginTop: "15px",
                boxShadow: "0px 0px 10px #142C441A",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "5px 8px",
                  background: "rgba(17, 92, 166, 0.1)",
                  color: "#142C44CC",
                  fontSize: "20px",
                  fontFamily: "Manrope,sans-serif",
                  fontWeight: 800,
                  fontSize: 14,
                }}
              >
                <div style={{ width: "50%" }}>Entity Name</div>
                <div style={{ width: "15%" }}>Repayment Amount</div>
                <div style={{ width: "15%" }}>Payment Date</div>
              </div>
              {data
                .filter(
                  (dat) =>
                    dat.direct_debit !== false &&
                    dat.repayments.filter((repa) => repa.status === "pending")
                      .length > 0 &&
                    dat.direct_debit.status
                )
                .map((pay, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "12px 8px",
                      background:
                        index % 2 ? "rgba(17, 92, 166, 0.1)" : "white",
                      color: "#142C44CC",
                      fontFamily: "Manrope,sans-serif",
                      fontWeight: 500,
                      fontSize: 12,
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <input
                        type="checkbox"
                        style={{
                          width: "15px",
                          height: "15px",
                          marginBottom: "0px",
                        }}
                        checked={checkboxChecked.includes(index)}
                        onChange={() => handleCheckboxChange(index)}
                      />
                      {pay?.borrower_details?.entity_name}
                    </div>
                    <div
                      style={{
                        width: "15%",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      {pay?.repayments?.[0]?.repay_amount?.toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "SGD",
                        }
                      )}
                    </div>
                    <div
                      style={{
                        width: "15%",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      {formatDate(
                        new Date(
                          pay?.repayments?.filter(
                            (repa) => repa.status === "pending"
                          )?.[0].repay_due_date
                        )
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DelayedPayments;
